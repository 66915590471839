/* src/DialogBox.css */
.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Set a higher z-index value */
    transition: background-color 0.3s ease;
}

.dialog-box {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 400px;
    z-index: 10000; /* Set a higher z-index value */
    transform: translateX(-100%);
    transition: transform 0.3s ease;

}

.dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close-button {
    border: none;
    background-color: transparent;
    font-size: 24px;
    cursor: pointer;
}

.dialog-content {
    margin-top: 20px;
}
.dialog-overlay.open {
    background-color: rgba(0, 0, 0, 0.5);
}

/*.dialog-box.open {
    transform: translateX(0);
}*/

.dialog-box.slide-in {
    transform: translateX(0);
}

.dialog-box.slide-out {
    transform: translateX(-100%);
}


.dialog-icon {
    font-size: 30px;
}
.dialog-type-error{
    color: red;
}
.dialog-type-success{
    color: green;
}

/* For the regular circle-check icon */
.dialog-icon.dialog-type-success .fa-regular.fa-circle-check {
    font-size: 40px; /* Change the value to your desired font size */
}

/* For the solid triangle-exclamation icon */
.dialog-icon.dialog-type-error .icon {
    font-size: 40px; /* Change the value to your desired font size */
}

/* Define the spinning animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Apply the animation to the icon */
.dialog-box-icon {
    animation: spin 0.5s linear ; /* Adjust the duration and timing function as needed */
    transition: transform 0.3s ease; /* Add a smooth transition to the animation */
}